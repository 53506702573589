@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.app {
    scroll-behavior: smooth;
}

.alice-carousel {
    width: 100%;
    /* height: 50vh; */
}

.heading-font {
    font-family: 'Dancing Script', cursive;
}

/* .alice-carousel__stage{} */
/* .alice-carousel__stage-item{} */
.alice-carousel__prev-btn {
    display: none;
    /* font-size: 5vh;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -50% !important; */
}

/* .alice-carousel__prev-btn-item{} */
.alice-carousel__next-btn {
    display: none;
    /* font-size: 5vh;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -50% !important; */
}

/* .alice-carousel__next-btn-item{} */
/* .alice-carousel__play-btn{} */
/* .alice-carousel__play-btn-item{} */
.alice-carousel__dots {
    display: none;
}

/* .alice-carousel__dots-item{} */
/* .alice-carousel__slide-info{} */
/* .alice-carousel__slide-info-item{} */